/* ================= MAIN APP ================= */


html, body {
  /* IE 10-11 didn't like using min-height */
  margin: 0;
  height: 100vh;
  height: -webkit-fill-available;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
}

* {
  box-sizing: border-box;
}

p {
  max-width: 600px;
  margin: 0 auto 1rem;
}

.main-app {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100vh;
  height: -webkit-fill-available;
  overflow: auto;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  text-align: center;
  height: 100vh;
  height: -webkit-fill-available;
  margin: 0;
}

/* ================= HEADER  ================= */

.header {
  flex-shrink: 0; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #FAB6A1;
  color: #FAB6A1;
  position: sticky;
  z-index: 5;
}

.logo-center {
  font-family: feather ,sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.17;
  margin: auto;
}

.logo-left {
  font-family: feather ,sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.17;
}

.menu {
  padding-right: 20px;
  display: flex;
}

.menu .checkbox {
  height: 26px;
  width: 32px;
  padding: 0;
  margin: 0;
  z-index: 40;
  opacity: 0;
  cursor: pointer;
}

.menu .lines {
  height: 26px;
  width: 32px;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 30;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu .lines .line{
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #FAB6A1;
}

.menu .lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.menu .lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.menu .lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.menu input[type="checkbox"]:checked ~ .menu-items {
  transform: translateX(0);
}

.menu input[type="checkbox"]:checked ~ .menu-blur {
  transform: translateX(0);
}

.menu input[type="checkbox"]:checked ~ .lines .line1 {
  transform: rotate(45deg);
}

.menu input[type="checkbox"]:checked ~ .lines .line2 {
  transform: scaleY(0);
}

.menu input[type="checkbox"]:checked ~ .lines .line3 {
  transform: rotate(-45deg);
}

.menu .menu-blur {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  transform: translate(-150%);
  transition: transform 0.5s ease-in-out;
  background-color: grey;
  opacity: 70%;
  z-index: 10;
}

.menu .menu-items {
  position: absolute;
  top: 0px;
  left: 0px;
  padding-top: 120px;
  height: 100vh;
  width: 65%;
  max-width: 300px;
  transform: translate(-150%);
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;
  background-color: white;
  z-index: 20;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.5); 
  border-bottom: 5px solid #FAB6A1;

}

.menu .menu-items .menu-item {
  padding: 15px 20px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  cursor: poiner;
  border-top: 1px solid lightgrey;
}

.menu .menu-items .menu-item:last-of-type {
  border-bottom: 1px solid lightgrey;
}

.menu .menu-items .menu-item .menu-icon {
  margin-right: 10px;
}

.menu .menu-items .menu-item .menu-icon:hover {
  color: #DC3545;
}

.menu .menu-items .menu-item:hover {
  color: #DC3545;
}

.menu .menu-items .menu-item .menu-text {
  cursor: pointer;
}

.menu .menu-items .menu-item .menu-text:hover {
  color: #DC3545;
}

/* ================= FOOTER ================= */


.footer {
  /* place on the bottom */
  background: #882900;
  padding: 20px;
  color: white;
  text-align: center;
}

.company-footer {
  padding: 5px;
}

.link {
  font-family: din-round,sans-serif;
  font-size: 14px;
  color: white;
  text-decoration: none;
}

.copyright {
  font-size: 10px;
}



/* ================= GENERAL ================= */



.button {
  max-width: 260px;
  padding: 20px;
  text-align: center;
  font-size: 20px;
  margin: 14px auto;
  border-radius: 16px;
}

.green-button {
  border: 1px solid #882900;
  background-color: #882900;
  color: white;
}

.green-button:hover {
  background-color: #DC3545;
  border-color: #DC3545;
  cursor: pointer;
}

.white-button {
  max-width: 260px;
  background-color: #FFFFFF;
  border: 1px solid #882900;
  color: #882900;
}

.white-button:hover {
  border: 1px solid #DC3545;
  color:  #DC3545;
  cursor: pointer;
}

.form-outer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: auto;
}

.form-title {
  color: #882900;
  font-family: din-round,sans-serif;
  font-size: 28px;
  line-height: 40px;
  margin: 10px 0;
}

.form-input {
  max-width: 260px;
  color: #5F5F5F;
  padding: 20px;
  text-align: left;
  font-size: 20px;
  margin: 10px auto;
  border-radius: 16px;
  border: 1px solid #882900;
}

.form-button-outer {
  padding: 4px;
  max-width: 260px;
  align-items: center;
  margin: auto;
}

.form-button {
  min-width: 80px;
  padding: 8px;
  margin: 8px;
  border-radius: 16px;
  border: 1px solid #882900;
}


/* ================= LANDING PAGE ================= */
.landing-spinning-logo-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 0 30px 0;
}

.spinning-logo {
  height: calc(100px + 2vmin);
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .spinning-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.landing-title {
  /*inline-size: calc(150px + 2vmin);
  overflow-wrap: break-word;*/
  color: #882900;
  font-family: din-round,sans-serif;
  font-size: 28px;
  line-height: 40px;
  font-weight: 700;
  margin: 10px 0 0 0;
}

.landing-button-outer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.landing-button-box {
  align-items: center;
  width: 100%;
}


/* =================   FAQ     ================= */

.faq {
  /* place on the bottom */
  position: relative;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #FAB6A1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #882900;
}

.faq-title {
  font-family: din-round,sans-serif;
  font-size: 30px;
  line-height: 40px;
  margin: 10px 0;
  text-align: center;
}

.faq-accordion {
  max-width: 600px;
  margin: 1em auto;
  width: 90%;
}

.accordion-item {
  border-bottom: 1px solid #882900;
}

.accordion-question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;
  font-size: 22px;
  align-items: center;
  letter-spacing: .01em;
  font-weight: 500;
}

.accordion-answer {
  font-size: 16px;
  text-align: left;
  padding: 10px 20px 0px 0px;
  text-decoration: none;
}

.faq-page {
  text-align: left;
}

/* this doesn't seem to work /*
.faq-link {
  text-decoration: underline;
  text-decoration-color: #882900 !important;
}

/* ================= DONATE  ================= */
.donate-statement-title {
  font-size: 26px;
  align-items: center;
  color: #882900;
  max-width: 300px;
  margin: 10px auto;
}

.donate-statement {
  font-size: 18px;
  align-items: center;
  color: #882900;
  max-width: 250px;
  margin: 10px auto;
}

.donate-button {
  padding: 30px;
  margin: 20px auto;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #882900;
  color: #882900;
  display: flex;
  width: 0;
  min-width: 120px;
  justify-content: center;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.5); 
  text-decoration: none !important;
}

.donate-button:hover {
  border: 1px solid #DC3545;
  color:  #DC3545;
  cursor: pointer;
}


/* ================= LOGIN PAGE ================= */

.login-content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  overflow: auto;
  text-align: center;
  align-items: stretch;
  background-color: #FAB6A1;
}

.login-box {
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.5); 
  font-size: 12px;
  color: #5F5F5F;
}

.login-cancel {
  width: 40px !important;
  height: 40px !important;
  color: #FAB6A1;
  margin-left: auto;
  padding: 10px 10px 0 0;
}

.login-cancel:hover {
  color: #DC3545;
}

.login-outer {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  min-width: 280px;
  justify-content: center;
  margin: auto;
}

.user-select-button {
  padding: 30px;
  margin: 20px 80px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #882900;
  color: #882900;
  display: flex;
  width: 0;
  min-width: 120px;
  justify-content: center;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.5); 

}

.user-select-button:hover {
  border: 1px solid #DC3545;
  color: #DC3545;
  cursor: pointer;
}

.login-title {
  color: #882900;
  font-family: din-round,sans-serif;
  font-size: 20px;
  margin: 0px 0 25px 0;
}

.login-input-label {
  padding-left: 35px;
  margin-right: auto;
  margin-bottom: 3px;
}

.login-input {
  margin: 0 35px 10px 35px;
  border: 1px solid grey;
  border-radius: 4px;
  padding: 10px;
}

.login-error-outer {
  margin: 0 35px 10px 35px;
  max-width: 210px;
}

.login-error-text {
  color: red;
}

.login-button {
  max-width: 260px;
  padding: 10px 20px;
  text-align: center;
  margin: 7px 35px 0 35px;
  border-radius: 4px;
  border: 1px solid #882900;
  background-color: #882900;
  color: white;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.5); 
}

.login-button:hover {
  background-color: #DC3545;
  border-color: #DC3545;
  cursor: pointer;
}

.forgot-password {
  margin-left: auto;
  padding: 8px 35px 0 0;
  cursor: pointer;
}

.password-reset-statement {
  font-size: 14px;
  text-align: left !important;
  color: #5F5F5F;
  max-width: 250px;
  padding-left: 35px;
  margin-right: auto;
  margin-bottom: 25px;
}

.email-sent-statement {
  font-size: 14px;
  color: #5F5F5F;
  max-width: 250px;
  padding-left: 35px;
  margin-right: auto;
  margin-bottom: 25px;
}

.login-or {
  height: 10px;
  border-bottom: 1px solid lightgrey; 
  text-align: center;
  margin: 20px 35px;
  display: hidden;

}

.login-or-text {
  background-color: white; 
  padding: 0 10px;
  border: 1px solid lightgrey; 
  display: hidden;
}

.login-code-outer {
  margin: 35px; 
}

.child-login-text {
  max-width: 40px;
  color: #5F5F5F;
  padding: 10px;
  margin: 5px;
  border-radius: 4px;
  border: 1px solid #882900;
}

/* ================= PARENT HOME ================= */

.parent-content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  text-align: center;
  height: 100vh;
  height: -webkit-fill-available;
  margin: 0;
  position: relative;
  z-index: 2; 
}

.parent-outer{
  display: flex;
  flex: 1 0 auto; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  margin: 0;
  background-color: #FAB6A1;
  position: relative;
  z-index: 3; /* Set this to lower than hamburger menu, so that blurring on hamb menu covers all the position elements below */
}

.parent-box {
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.5); 
  color: #5F5F5F;
  max-width: 90%;
}

.parent-home {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  overflow: auto;
  text-align: center;
  max-width: 600px;
  min-width: 330px;
  min-height: 400px;
}

.piggy-nav-outer {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  /*margin: 40px 0 0 0;*/
  background-color: white;
  min-width: 330px;
}

.action-button-outer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 0px auto 20px auto;
  background-color: white;
}

/* ================= PIGGY NAV ================= */

.piggy-nav-save {
  color: white;
  background-color: #882900;
  padding: 10px;
  font-size: 20px;
}

.piggy-nav-save:hover {
  cursor: pointer;
}

.piggy-nav-piggies-outer {
  display: flex;
  flex-direction: row;
  padding: 40px 0 0 0;
  align-items: center;
  justify-content: center;
  margin: 0;
}


/* middle circle */
.piggy-nav-center-outer {
  align-content: center;
  margin: auto;
}

.piggy-nav-center-circle {
  width:150px;
  height:150px;
  border-radius:250px;
  font-size:calc(50px);
  font-weight: bold;
  color:#FAB6A1;
  line-height:150px;
  text-align:center;
  border-color: #FAB6A1;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  margin: auto;
  position: relative;
}

.piggy-nav-center-circle:hover {
  
  color:#DC3545;
  border-color: #DC3545;
}



.piggy-nav-center-circle .piggy-nav-approvals-circle {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background: #dc3545;
  color: #FFFFFF;
  font-size: 20px;
}

.piggy-nav-approvals-text {
  color: #FFFFFF;
  font-size: 20px;
  position: relative;
  top: -55px;
}

/*
moved settings higher up
.piggy-nav-center-circle .piggy-settings {
  position: absolute;
  top: -5px;
  right: 110px;
  height: 40px;
  background-color: white;
}
*/

.piggy-settings {
  width: 40px !important;
  height: 40px !important;
  color: #FAB6A1;
  margin-left: auto;
  padding: 10px 10px 0 0;
}


/* piggy name */
.piggy-nav-name {
  font-family: din-round,sans-serif;
  font-size: 30px;
  color: #FAB6A1;
  padding: 15px 0;
}

.next-arrow {
  height: 90px !important;
  width: 90px !important;
  color: #FAB6A1;
  
}



/* ================= PIGGY SETTINGS  ================= */


.settings-header {
  font-family: din-round,sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #882900;
  padding-top: 15px;
  text-align: left;
}

.settings-action {
  color: #FAB6A1;
  /*margin-left: 15px;*/
  margin-bottom: 40px;
}

.profile-settings {
  margin: 20px 15px;
  display: flex;
  flex-direction: column;
}

.profile-element {
  font-family: din-round,sans-serif;
  font-size: 20px;
  color: #5F5F5F;
  padding: 10px 0px;
  border-top: 1px solid #FAB6A1;
  display: flex;
  flex-direction: row;
}

.profile-element:last-child 
{
  border-bottom: 1px solid #FAB6A1;
}

.profile-element-left {
  min-width: 40%;
  text-align: left;
}

.profile-element-right {
  min-width: 40%;
  text-align: right;
}

.profile-controls {
  min-width: 20%;
  flex-grow: 1;
  text-align: right;
  margin: auto;
}

.profile-element-na {
  font-size: 14px;
}

.settings-table {
  overflow: auto;
  max-height: 200px;
}

.job-settings {
  margin: 20px 15px;
  display: flex;
  flex-direction: column;
}

.job-element-header {
  font-weight: bold;
  font-family: din-round,sans-serif;
  font-size: 20px;
  color: #5F5F5F;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
}

.job-element-left {
  min-width: 65%;
  text-align: left;
}

.job-element-right {
  min-width: 15%;
  text-align: right;
}

.jobs-input {
  width: 95%;
  color: #5F5F5F;
  text-align: left;
  font-size: 16px;
  border-radius: 16px;
  border: 1px solid #882900;
  padding: 10px;
}

.job-controls {
  min-width: 20%;
  flex-grow: 1;
  text-align: right;
  margin: auto;
}

/* ================= PRINT ACTIONS  ================= */


.print-only {
  display: none;
}


@media print {
  .no-print {
      display: none;
  }

  .print-only{
      display: flex;
      flex: 1 0 auto;
      max-width: 500px;
      min-height: 100vh;
      margin: auto;
      text-align: center;
  }
}



.print-box {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  overflow: auto;
  margin: auto;
  min-width: 350px;

}

.print-header {
  font-family: din-round,sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #882900;
  padding-top: 15px;
  text-align: center;
}

.print-job-element-left {
  min-width: 65%;
  text-align: left;
}

.print-job-element-right {
  min-width: 35%;
  text-align: right;
}

.print-logo {
  font-family: feather ,sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.17;
  color: #882900;
}

/* ================= APPROVALS / TRANSACTIONS  ================= */
.transactions-table {
  overflow: auto;
  max-height: 400px;
  max-width: 90%;
  margin: auto;
  font-size: 12px;
}

.column-no-wrap {
   white-space: nowrap;
}

.column-pad {
   padding: 0 10px;
}

/* ================= ADD CHILD  ================= */

.add-child-box {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #5F5F5F;
}


/* ================= CHILD  ================= */
/* ================= MAKE MONEY  ================= */

.child-nav-outer {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 40px 0 0 0; /* need this for rounded top corners and better spacing without settings button */
  background-color: white;
  min-width: 330px;
}

.child-job-outer {
  border-radius: 8px;
  border: 1px solid #FAB6A1;
  margin: 5px;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.child-job-text {
  color: #5F5F5F;
  padding: 10px;
}

.child-job-check {
  margin: 10px;
  cursor: pointer;
  height: 25px;
  width: 25px;
  align-self: center;
}

.child-earnings {
  font-size:calc(30px);
  font-weight: bold;
  color:#882900;
  padding: 20px;
  text-align:center;
}

























/* ================= GRAVEYARD ================= */





.App {
  text-align: center;
}

/* ================== */
/* overwriting bootstrap styles */
/* color:  #198754; 777777 */
.navbar-light .navbar-brand {
  color:  #78C73D;
  text-align: center;
  margin: 0;
}

.navbar-light .navbar-brand:hover {
  color:  #78C73D;
  text-align: center;
  margin: 0;
}

.btn {
  font-size: calc(5px + 2vmin);
}

.container-fluid {
  padding: 0;
  margin: 0;
  align-items: center;
}

.row {
  align-items: center;
  margin: 0;
}

.col {
  align-items: center;
}

.col-sm-12 {
  padding: 0;
  align-items: center;
}

.MuiSvgIcon-root:hover {
  fill: #dc3545;
  cursor: pointer;
}

.form-control {
  color: #5F5F5F;
  padding: 20px;
  text-align: left;
  font-size: 20px;
  margin: 4px 2px;
  border-radius: 16px;
  border: 1px solid #198754;
}

.btn-outline-danger {
    color: #78C73D;
    border-color: #78C73D;
}

.btn-outline-danger:hover {
    background-color: #50C73D;
    border-color: #50C73D;
    color:  white;
    cursor: pointer;
}


/* ================== */
/* PRINTING OF CHORES LIST */

.PrintOnly {
  display: none;
}

.App-link {
  color: #61dafb;
}

/*@media print { */

  .ActionsHeader {
    margin-bottom: 2vw;
  }
  .ActionsLogo {
    height: 10vmin;
    display:inline;
    margin-left: 10vw;
    margin-right: 10vw;
  }

  .ActionsNameHeader {
    display:inline-block;
    vertical-align: bottom;
  }

  .ActionsChildName {
    font-family: feather ,sans-serif;
    font-size: 42px;
    font-weight: 700;
    line-height: 1.17;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .ActionsSimplePiggyList {
    font-family: feather ,sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.17;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .ActionsText {
    cursor: pointer;
    color: #198754;
    padding: 10px;
    font-family: din-round,sans-serif;
    font-size: calc(20px + 2vmin);
    line-height: 40px;
    font-weight: 700;
    text-align: center;
  }

  .ActionsPrintBox {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #777777;
  }

  .ActionsInnerBox {
    max-width:  80vw;
  }

  .ActionsRow {
    color: #198754;
    align-items: center;
    justify-content: center;
    display: flex; 
  }

  .ActionsCol {
    color: #198754;
    border-color: #198754;
    border-width: 1px;
    border-style: solid;
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 5vh;
    padding: 10px;
    height: 25vh;
  
  }
        


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ================== */
/* BUTTONS */

.GreenButton {
  background-color: #78C73D;
  border: none;
  color: white;
  padding: 20px;
  text-align: center;
  font-size: 20px;
  margin: 4px 2px;
  border-radius: 16px;
}

.GreenButtonAlt {
  background-color: #ffffff;
  border: 1px solid #78C73D;
  color: #78C73D;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 16px;
}

.GreenButton:hover {
  background-color: #50C73D;
  color:  white;
  cursor: pointer;
}

.GreenButtonAlt:hover {
  background-color: #50C73D;
  color:  white;
  cursor: pointer;
}



/* ================== */
/* Landing Page */

.App-body-landing {
  min-height: 55vh;
  color: #777777;
  /*background-image: url("bg_2.png"); */
}

.App-body-buyflow {
  min-height: 85vh;
}

/*
.App-body-landing {
  background-color: #282c34; #d1ffe0; B4E0D8 
  background-color: #d1ffe0;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #777777;
  background-image: url("bg_l.png"); 

}
*/

.App-logo {
  height: calc(150px + 2vmin);
  pointer-events: none;
  margin-bottom: 50px;
  margin-top: 50px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Logo {
  font-family: feather ,sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.17;
}

.Box{
  width: 85%;
  max-width: 520px;
}

.ButtonBox{
  padding: 25px 50px;
  max-width: 520px;
  margin: auto; 
}

.PitchPhrase {
  color: #198754;
  /*font-family: feather;*/
  font-family: din-round,sans-serif;
  font-size: 28px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
}

.JoinPhrase {
  padding: 10px;
  font-family: din-round,sans-serif;
  font-size: 20px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
}

.FlowPhrase {
  font-family: din-round,sans-serif;
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  text-align: left;
  color: #198754;
  padding-bottom: 40px;
}



/* ================== */


.Nav {
  background-color: #ffffff;
  /*background-image: url("bg_l.png"); */

  min-height: 10vh;
  /*padding: 0 4vw;
  /*display: flex;
  flex-direction: column;
  /*align-items: center;*/
  justify-content: center;
  font-size: calc(10px + 2vmin);
  border-bottom: 1px solid #198754;
  padding:  10px;
}

.Footer {
  background-color: #ffffff;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #78C73D;
  border-top: 1px solid #198754;
}

.Login {
  margin-left: auto;
}


.FormButtons {
  padding: 4px;
  max-width: 260px;
  align-items: center;
  margin: auto;

}
.FormInputs {
  padding: 4px;
}
.FormInput {
  float: left;
}


/* ================== */
/* CHILD */
.App-child {
  /*background-color: #282c34; #d1ffe0; B4E0D8 */
  background-color: #d1ffe0;
  min-height: 85vh;
  color: #777777;
}

.ChildBody {
  /*background-color: #282c34; #d1ffe0; B4E0D8 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ChildName {
  padding: 10px;
  font-family: din-round,sans-serif;
  font-size: 20px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
}

.ChildMoney {
  padding: 10px;
  font-family: din-round,sans-serif;
  font-size: 20px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
}

/* ================== */
/* PARENT */

.App-parent {
  /*background-color: #282c34; #d1ffe0; B4E0D8 */
  min-height: 85vh;
  color: #198754;
}


.Error {
  color: red;
  padding: 4px;
}

.ParentBody {
  /*background-color: #282c34; #d1ffe0; B4E0D8 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 55vh;
}

.ParentFullScreen {
  /*background-color: #282c34; #d1ffe0; B4E0D8 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 85vh;
}


/* ================== */
/* ADD PIGGY */

.AddChildPhrase {
  text-align: center;
}

.CreateAccountSH {
  /*height: calc(150px + 2vmin);*/
  height:  250px;
  pointer-events: none;
  margin: 0 auto;
  display: block;
}

.AddChildFlowPhrase {
  font-family: din-round,sans-serif;
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  text-align: center;
  color: #198754;
  padding-bottom: 40px;
}

/* ================== */
/* Adjust Actions */

.FullWidth {
  max-width: 540px;
  padding-left: 10px;
}

.ActionsTable {
  color: #777777;
  max-width: 540px;
}

.NewLineButton {
  
}



/* ================== */
/* overwriting bootstrap styles */







